<template>
    <div class="medalList">
        <div class="userInfor">
            <dl>
                <dt>
                    <img :src="$store.getters.personal.portrait" alt="">
                </dt>
                <dd>
                    <span class="c33 ">{{user == 3 ? $store.getters.personal.hospital.full_name : $store.getters.personal.real_name}}</span>
					<span style="padding-left: 20px;"> 总积分：{{$store.getters.personal.point_all}}</span>
                    <p><img :src="$store.getters.personal.icon" alt=""><a style="padding-left: 10px;">{{$store.getters.personal.medal_name}}</a></p>
                </dd>
            </dl>
        </div>
        <div class="medalTable">
            <p class="fontW">勋章规则</p>
            <table cellspacing="0">
                <tr>
                    <th style="width:256px;">称号</th>
                    <th>徽章</th>
                    <th>升级规则</th>
                </tr>
                <tr v-for="(im,ix) in medalList" :key="ix">
                    <td style="width:256px;height:82px;">{{im.name}}</td>
                    <td style="position:relative;width:296px;height:82px;" class="">
                        <img :src="im.icon" style="position:absolute;width:99px;height:30px;left:0;right:0;top:0;bottom:0;margin:auto;" alt="">
						<span style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;color:#fff;padding-top: 33px;padding-left: 20px;">{{im.name}}</span>
                        <!-- <span style="position:relative;z-index:999;padding-left:25px;padding-top:28px;">{{im.name}}</span> -->
                    </td>
                    <td style="width:294px;height:82px;">{{im.desc}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { medal_list } from '@/utils/Api/commonList'
export default {
    name:'medalList',
    data(){
        return{
            medalList:[]
        }
    },
    created(){
    },
	mounted() {
		setTimeout(() => {this.initial()},200)
	},
	computed:{
		user(){
			console.log(this.$store.getters.personal.capacity)
			return this.$store.getters.personal.capacity
		}
	},
    methods:{
        async initial(){
			console.log(this.user)
            let type = this.user == 3 ? 2 : 1
			console.log(type)
            let callData = await medal_list({type: type})
            if(callData){
                this.medalList = callData
            }else{
                this.$message.error('勋章信息获取失败')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.userInfor{
    // width: 380px;
    height: 88px;
    margin-left: 69px;
    margin-top: 50px;
    dl{
        dt{
            width: 88px;
            height: 88px;
            border-radius: 100px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            float: left;
        }
        dd{
            span{
                font-size: 15px;
                display: inline-block;
                margin-top: 10px;
            }
            float: left;
            margin-left: 24px;
            p{
                width: 90px;
                height: 30px;
                position: relative;
                margin-top: 14px;
                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
					top: 0;
					left: 0;
                }
                a{
                    display: inline-block;
                    margin-top: 8px;
                    margin-left: 10px;
                    font-size: 13px;
                    color: #fff;
                    position: relative;
                    z-index: 999;
                }
            }
        }
    }
}
.medalList{
    .medalTable{
        width: 850px;
        margin-left: 30px;
        p{
            width: 100%;
            text-align: right;
            font-size: 16px;
            margin-bottom: 21px;
            margin-top: 26px;
        }
        table{
            tr{
                height: 82px;
            }
            th{
                background: #fafafa;
                font-size: 14px;
            }
            td{
                border: 1px solid #e8e8e8;
                overflow: hidden;
                font-size: 14px;
                color: #333;
            }
        }
    }
}
</style>